import { useEffect, useState } from "react";
import "./CandidateTable.scss";
import { candidateForTheCsm } from "../../APIS/getCandidateforCsm";
import { candidateCallStatus } from "../../APIS/setCallStatusForCandidate";
import Popup from "../Common/popUp/popUp";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { toast } from "react-toastify";
import { handleInvite } from "../../APIS/handleInvite";
import { CandidateProfile } from "../Common/CandidateProfile/CandidateProfile";
import { getAllCountries } from "../../APIS/getCountries";

const Headers = [
  "Candidate Name",
  "Email",
  "Contact no.",
  "Job Role",
  "Location",
  "Experience",
  // "Skills",
  "DOB",
  "Nationality",
  "Rating",
  "Availibility",
  "OFAC",
  "Update Status",
];
const today = new Date().toISOString().split("T")[0];

const todayDate = new Date();
const eighteenYearsAgo = new Date(
  todayDate.getFullYear() - 18,
  todayDate.getMonth(),
  todayDate.getDate()
)
  .toISOString()
  .split("T")[0];

export const CandidateTable = ({ selectedTypeOfApplication, currentPage }) => {
  // const [tableData, setTableData] = useState([...DummyData]);
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([...Headers]);
  const [changeCallStatus, setChangeCallStatus] = useState(false);
  const [changeAvailibility, setChangeAvailibility] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedName, setSelectedName] = useState("");

  const [viewProfile, setViewProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [countries, setCountries] = useState([]);

  const getAllCandidatesForCsm = async () => {
    if (
      selectedTypeOfApplication !== "Call Pending" &&
      selectedTypeOfApplication !== "Call Completed"
    )
      return;
    try {
      const email = sessionStorage.getItem("email");
      const res = await candidateForTheCsm(
        email,
        selectedTypeOfApplication,
        currentPage
      );
      setTableData([...res]);
    } catch (err) {
      console.log("getAllCandidatesForCsm==>", err);
      setTableData([]);
    }
  };

  const handleCallStatus = async (email) => {
    try {
      const res = await candidateCallStatus(email);
      if (res) {
        getAllCandidatesForCsm();
        setChangeCallStatus(false);
        setSelectedEmail(null);
      }
    } catch (err) {
      console.log("handleCallStatus==>", err);
    }
  };

  const handleUpdate = (email, name) => {
    setSelectedEmail(email);
    setSelectedName(name);
    if (selectedTypeOfApplication === "Call Pending") {
      setChangeCallStatus(true);
      setChangeAvailibility(false);
    } else if (selectedTypeOfApplication === "Call Completed") {
      setChangeCallStatus(false);
      setChangeAvailibility(true);
    }
  };

  const handleViewProfile = (candidateID) => {
    setViewProfile(true);
    setSelectedProfile(candidateID);
  };

  const getAllNationalities = async () => {
    try {
      const res = await getAllCountries();
      // console.log(res);
      setCountries([...res]);
    } catch (err) {
      console.error("getAllNationalities==.", err);
    }
  };

  useEffect(() => {
    getAllCandidatesForCsm();
  }, [selectedTypeOfApplication, currentPage]);

  useEffect(() => {
    getAllNationalities();
  }, []);

  return (
    <>
      <div className="CandidateWrapper">
        <table className="applicationTableContainer">
          <thead className="tableHead">
            <tr className="tableRow">
              {tableHeaders?.map((header, index) => (
                <th key={index} className="headerContent">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tableBody">
            {tableData?.map((data, index) => (
              <tr key={index}>
                <td>{data?.candidateName}</td>
                <td>{data?.email}</td>
                <td>{data?.contactNumber}</td>
                <td>{data?.jobRole}</td>
                <td>{data?.location}</td>
                <td>{data?.experience}</td>
                {/* <td className="skills">{data?.skills.join(", ")}</td> */}
                <td>{data?.dob}</td>
                <td>{data?.citizenship}</td>
                <td>{data?.rating}</td>
                <td>{data?.availability}</td>
                <td>
                  {`${data?.ofacStatus}`.charAt(0).toUpperCase() +
                    `${data?.ofacStatus}`.slice(1)}
                </td>
                <td className="endButtons">
                  <button
                    className="sendButton"
                    onClick={() =>
                      handleUpdate(data?.email, data?.candidateName)
                    }
                  >
                    Update
                  </button>
                  <button
                    className="viewProfile"
                    onClick={() => handleViewProfile(data?.email)}
                  >
                    View Profile
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {changeCallStatus && (
        <ViewChangeCallStatus
          setChangeCallStatus={setChangeCallStatus}
          handleCallStatus={handleCallStatus}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          setChangeAvailibility={setChangeAvailibility}
          getAllCandidatesForCsm={getAllCandidatesForCsm}
          countries={countries}
          selectedName={selectedName}
        />
      )}
      {changeAvailibility && (
        <ViewChangeAvailibility
          setChangeAvailibility={setChangeAvailibility}
          selectedEmail={selectedEmail}
          getAllCandidatesForCsm={getAllCandidatesForCsm}
          countries={countries}
          selectedName={selectedName}
        />
      )}
      {viewProfile && (
        <CandidateProfile
          candidateId={selectedProfile}
          setViewProfile={setViewProfile}
        />
      )}
    </>
  );
};

const ViewChangeCallStatus = ({
  setChangeCallStatus,
  handleCallStatus,
  selectedEmail,
  setSelectedEmail,
  setChangeAvailibility,
  getAllCandidatesForCsm,
  countries,
  selectedName,
}) => {
  const [status, setStatus] = useState({});
  const [availibility, setAvailibility] = useState("");
  const [availibilityTime, setAvailibilityTime] = useState();
  const [days, setDays] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState();

  const handleChange = (val) => {
    setAvailibility(val.value);
  };

  const handleChangeVal = (val) => {
    // console.log(val);
    setStatus({ ...val });
  };

  const handleUpdateAvailibility = async () => {
    try {
      const data = {
        name: selectedEmail,
        availability: availibility,
        dob: dob,
        citizenship: nationality?.value,
        candName: selectedName,
      };
      if (availibility === availibilityOptions[2]?.value) {
        data["noticePeriod"] = days;
      } else if (availibility === availibilityOptions[1]?.value) {
        data["lastWorkingDate"] = availibilityTime;
      } else if (availibility === availibilityOptions[0]?.value) {
        data["joiningDate"] = availibilityTime;
      }

      const res = await handleInvite(data);
      if (res) {
        setChangeAvailibility(false);
        getAllCandidatesForCsm();
      }
      // console.log("handleUpdateAvailibility==>", data);
    } catch (err) {
      console.log("handleUpdate error==>", err);
      toast(err.mesage);
    }
  };

  const availibilityOptionsJoining = [
    { value: "Immediate", label: "Immediate" },
    { value: "Serving Notice period", label: "Serving Notice period" },
    { value: "Not yet resigned", label: "Not yet resigned" },
  ];

  const availibilityOptions = [
    { value: false, label: "Yet to Call" },
    { value: true, label: "Call Completed" },
  ];

  return (
    <Popup>
      <div className="availibility">
        <div className="close" onClick={() => setChangeCallStatus(false)}>
          <img src={"./Images/icons/Close.svg"} alt="cut" />
        </div>
        <div className="title1">Call Status</div>
        <Dropdown
          options={availibilityOptions}
          onChange={handleChangeVal}
          value={status?.label}
          placeholder="Select an option"
          className="dropdown"
          controlClassName="myControlClassName"
          menuClassName="myMenuClassName"
        />
        <div className="title1">Update Availibility</div>
        <div className="title2">Availability</div>
        <Dropdown
          options={availibilityOptionsJoining}
          onChange={handleChange}
          value={availibility}
          placeholder="Select an option"
          className="dropdown"
          controlClassName="myControlClassName"
          menuClassName="myMenuClassName"
        />
        {availibility === availibilityOptionsJoining[0]?.value && (
          <>
            {" "}
            <div className="title2"> Joining date</div>
            <input
              type="date"
              className="date"
              value={availibilityTime}
              onChange={(e) => setAvailibilityTime(e.target.value)}
              min={today}
            />{" "}
          </>
        )}
        {availibility === availibilityOptionsJoining[1]?.value && (
          <>
            {" "}
            <div className="title2"> Last working date</div>
            <input
              type="date"
              className="date"
              value={availibilityTime}
              min={today}
              onChange={(e) => setAvailibilityTime(e.target.value)}
            />{" "}
          </>
        )}
        {availibility === availibilityOptionsJoining[2]?.value && (
          <>
            {" "}
            <div className="title2"> Notice period</div>
            <input
              type="text"
              className="date"
              placeholder="0 days"
              value={days}
              onChange={(e) => setDays(e.target.value)}
            />{" "}
          </>
        )}

        {/* nationality */}
        <div className="title2" style={{ marginTop: "40px" }}>
          Nationality
        </div>
        <Dropdown
          options={countries}
          onChange={(e) => setNationality(e)}
          value={nationality}
          placeholder="Select an option"
          className="dropdown"
          controlClassName="myControlClassName"
          menuClassName="myMenuClassName"
        />
        {/* nationality */}

        {/* dob */}
        <div className="title2" style={{ marginTop: "40px" }}>
          {" "}
          Candidate Date of Birth
        </div>
        <input
          type="date"
          className="date"
          value={dob}
          max={eighteenYearsAgo}
          onChange={(e) => setDob(e.target.value)}
        />
        {/* dob */}
        <div>
          <button
            className="update"
            onClick={() => {
              if (status?.label) {
                handleCallStatus(selectedEmail);
              } else {
                setChangeCallStatus(false);
                setSelectedEmail(null);
              }
              handleUpdateAvailibility();
            }}
          >
            Update Changes
          </button>
        </div>
      </div>
    </Popup>
  );
};

const ViewChangeAvailibility = ({
  setChangeAvailibility,
  selectedEmail,
  getAllCandidatesForCsm,
  countries,
  selectedName,
}) => {
  const [availibility, setAvailibility] = useState("");
  const [availibilityTime, setAvailibilityTime] = useState();
  const [days, setDays] = useState("");
  const [dob, setDob] = useState("");
  const [nationality, setNationality] = useState();

  const handleChange = (val) => {
    setAvailibility(val.value);
  };

  const availibilityOptions = [
    { value: "Immediate", label: "Immediate" },
    { value: "Serving Notice period", label: "Serving Notice period" },
    { value: "Not yet resigned", label: "Not yet resigned" },
  ];

  const handleUpdateAvailibility = async () => {
    try {
      const data = {
        name: selectedEmail,
        availability: availibility,
        dob: dob,
        citizenship: nationality?.value,
        candName: selectedName,
      };
      if (availibility === availibilityOptions[2]?.value) {
        data["noticePeriod"] = days;
      } else if (availibility === availibilityOptions[1]?.value) {
        data["lastWorkingDate"] = availibilityTime;
      } else if (availibility === availibilityOptions[0]?.value) {
        data["joiningDate"] = availibilityTime;
      }

      const res = await handleInvite(data);
      if (res) {
        setChangeAvailibility(false);
        getAllCandidatesForCsm();
      }
      // console.log("handleUpdateAvailibility==>", data);
    } catch (err) {
      console.log("handleUpdate error==>", err);
      toast(err.mesage);
    }
  };

  return (
    <Popup>
      <div className="availibility">
        <div className="close" onClick={() => setChangeAvailibility(false)}>
          <img src={"./Images/icons/Close.svg"} alt="cut" />
        </div>
        <div className="title1">Update Availibility</div>
        <div className="title2">Availability</div>
        <Dropdown
          options={availibilityOptions}
          onChange={handleChange}
          value={availibility}
          placeholder="Select an option"
          className="dropdown"
          controlClassName="myControlClassName"
          menuClassName="myMenuClassName"
        />
        {availibility === availibilityOptions[0]?.value && (
          <>
            {" "}
            <div className="title2"> Joining date</div>
            <input
              type="date"
              className="date"
              value={availibilityTime}
              min={today}
              onChange={(e) => setAvailibilityTime(e.target.value)}
            />{" "}
          </>
        )}
        {availibility === availibilityOptions[1]?.value && (
          <>
            {" "}
            <div className="title2"> Last working date</div>
            <input
              type="date"
              className="date"
              value={availibilityTime}
              min={today}
              onChange={(e) => setAvailibilityTime(e.target.value)}
            />{" "}
          </>
        )}
        {availibility === availibilityOptions[2]?.value && (
          <>
            {" "}
            <div className="title2"> Notice period</div>
            <input
              type="text"
              className="date"
              placeholder="0 days"
              value={days}
              onChange={(e) => setDays(e.target.value)}
            />{" "}
          </>
        )}

        {/* nationality */}
        <div className="title2" style={{ marginTop: "40px" }}>
          Nationality
        </div>
        <Dropdown
          options={countries}
          onChange={(e) => setNationality(e)}
          value={nationality}
          placeholder="Select an option"
          className="dropdown"
          controlClassName="myControlClassName"
          menuClassName="myMenuClassName"
        />
        {/* nationality */}

        {/* dob */}
        <div className="title2" style={{ marginTop: "40px" }}>
          {" "}
          Candidate Date of Birth
        </div>
        <input
          type="date"
          className="date"
          value={dob}
          max={eighteenYearsAgo}
          onChange={(e) => setDob(e.target.value)}
        />
        {/* dob */}

        <div>
          <button className="update" onClick={() => handleUpdateAvailibility()}>
            Update Changes
          </button>
        </div>
      </div>
    </Popup>
  );
};
