import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from "../Pages/Login/Login";
import { DashboardCdsm } from "../Pages/DashboardCdsm/DashboardCdsm";
import { SendJobInvites } from "../Pages/SendInvites/SendInvites";
import { InvitesPage } from "../Pages/InvitesPage/InvitesPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />}></Route>
        <Route exact path="/dashboard" element={<DashboardCdsm />}></Route>
        <Route exact path="/send-invites" element={<SendJobInvites />}></Route>
        <Route exact path="/sent-invites" element={<InvitesPage />}></Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
    // this change is to test deployment
  );
};
